:global {
    /*HOME  PAGE*/
    /*HEADER*/
    .header-menu-top {
        margin-bottom: 0.7%;
        padding: 0 !important;
        background-color: #000000;
        color: white;
    }

    .header-menu-top .dropdown-menu {
        left: -60px!important;
    }

    .header-menu-top .nav-item {
        padding: .5rem 1rem;
    }

    .nav-divider-wrappper {
        display: flex;
        display: -ms-flexbox;
        display: -webkit-flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        margin: 0 1px;
    }

    .nav-divider {
        border-right: 0.5px solid white;
        height: 12px;
        width: 1px;
    }

    .header-menu-top .nav-link {
        color: white;
        font-family: NunitoSans-Light, serif;
        font-size: 13px;
        padding: 0;
        line-height: 1.7;
    }

    a.nav-link {
        font-size: 13px;
    }

    .header-menu-top .dropdown-toggle::after {
        display: none !important;
    }

    .navbar-brand {
        font-family: NunitoSans-ExtraBold, serif;
        font-size: 30px;
    }

    .navbar-brand:hover {
        cursor: pointer;
    }

    .navbar-brand-wrapper {
        text-align: right;
    }

    .navbar-toggler {
        color: black;
        height: 100%;
        width: auto;
        position: relative;
        top: 0;
        bottom: 0;
        font-size: 24px !important;
        float: left;
        height: 51px;
    }

    .navbar-toggler a, .navbar-toggler a:hover, .navbar-toggler a:focus {
        color: black;
    }

    .nav-wrapper {
        text-align: right;
        display: inline;
        position: relative;
    }

    .nav-wrapper .nav {
        float: right;
        position: relative;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        height: 100%;
    }

    .nav-wrapper .nav a, .nav-wrapper .nav a:hover {
        color: black;
    }

    .nav-wrapper .nav a:hover {
        text-decoration: underline;
    }
    .header-menu-main {
        width: 100%;
        margin: 0 auto;
        padding: .5rem 1rem 0 1rem;
    }

    .header-menu-main .nav {
        display: -webkit-box !important;
        width: 98.4%;
        margin: 0 0 0 auto;
    }

    .header-menu-main .nav-link.active {
        color: #f0632f;
        text-decoration: none;
    }

    .header-menu-main .nav-link {
        color: black;
        font-family: NunitoSans-Regular, serif;
        font-size: 13px;
    }

    .header-menu-main .nav-item {
        margin: 0 1%;
        padding: 0.9rem 1.1rem;
        min-width: fit-content;
    }

    .header-menu-main .nav-item.show.active {
        background: #f0632f;
    }

    .header-menu-main .nav-item.show {
        background: black;
    }

    .header-menu-main .nav-item.show .nav-link {
        color: white !important;
    }

    .header-menu-main .nav .nav-item a.nav-link {
        padding: 0 !important;
        min-height: 20px;
        line-height: 20px!important;
    }

    .header-menu-main .nav .nav-item:hover a.nav-link {
        text-decoration: none;
    }

    .header-menu-main .dropdown {
        position: initial !important;
    }

    .header-menu-main .dropdown-menu {
        width: 100% !important;
        height: 400px;
        top: 94% !important;
        padding: 1.5rem 0 0.5rem 0;
    }

    .sous-menu {
        margin-left: 2.3rem;
    }

    .sous-menu.row {
        height: 100%;
    }

    .sous-menu-main {
        margin-left: 2.1%!important;
        height: 100%;
        width: 100%;
        position: relative;
    }

    .sous-menu-main li {
        font-family: NunitoSans-Bold, serif;
        padding: 4px;
        width: 95%;
        font-size: 15px;
    }

    .sous-menu-main li.active .sous-submenu {
        display: block !important;
    }

    .sous-menu-main li.active {
        background: #e5e5e5;
    }

    /*
    .sous-menu-main li:first-child.active {
    background: transparent;
    }
     */

    a.sous-menu-main-item-title, a.sous-menu-main-item-title:hover, a.sous-menu-main-item-title:active, a.sous-menu-main-item-title:focus {
        color: black !important;
    }

    .sous-menu-main li.active .sous-menu-main-item-title i {
        opacity: 1;
    }

    .sous-menu-main-item:hover {
        background: #e5e5e5;
    }

    .sous-menu-main-item-title {
        padding-left: 3px !important;
    }

    .sous-menu-main li i {
        opacity: 0;
        position: relative;
        float: right;
        right: 2px;
        top: 4px;
        transition: opacity .3s;
        -webkit-transition: opacity .3s;
        -o-transition: opacity .3s;
        -moz-transition: opacity .3s;
    }

    .sous-menu-main-item:hover i {
        opacity: 1;
        transition: opacity .3s;
        -webkit-transition: opacity .3s;
        -o-transition: opacity .3s;
        -moz-transition: opacity .3s;
    }

    .sous-menu-main-items {
        width: 360px;
        position: relative;
    }

    .sous-menu-main-items li.sous-menu-main-item-wm {
        min-height: 30px;
        margin-bottom: 8px;
    }

    .sous-submenu {
        left: 100%;
        position: absolute;
        width: 1240px;
        top: 8px;
        bottom: 0;
        display: none;
        height: 22.35rem;
        /*padding-bottom: 10px;*/
    }

    .sous-submenu .row {
        height: 100%;
    }

    .sous-submenu ul {
        border-left: 1px solid #cdcdcd;
        height: 265px;
    }

    .sous-submenu li:first-child {
        padding-top: 0;
        line-height: 1;
        margin-bottom: -10px;
    }

    .sous-submenu li:last-child {
        padding-bottom: 0;
    }

    .sous-submenu li {
        padding-left: 5%;
        color: #777777;
        font-family: NunitoSans-Light, serif;
        font-size: 15px;
        padding-bottom: 5px;
        border: none;
        min-height: 30px;
    }

    .sous-submenu li.caption a {
        color: black !important;
        font-family: NunitoSans-Regular, serif;
        font-size: 15px;
    }

    .sous-submenu .col-3 {
        min-width: 380px !important;
    }

    .sous-submenu .sous-menu-btn {
        margin-left: 5%;
    }

    .sous-menu-btn {
        position: absolute;
        bottom: 5%;
    }

    .sous-menu-btn {
        max-width: 90%;
    }


    @media (max-width: 1950px) {
        .producteur-de-la-semaine-wrapper {
            width: 90%;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 32% !important;
        }

    }

    @media (max-width: 1866px) {
        .header-menu-main {
            padding: .5rem 0 0;
        }

        .header-menu-main .nav {
            width: 100%;
        }

        .header-menu-main .nav-item {
            margin: 0 0.9%;
            min-width: fit-content;
        }

        .header-slider-caption-wrapper {
            width: 85%;
        }

        .navbar-brand-wrapper {
            text-align: left;
            padding-left: 9%;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 30% !important;
        }

        #category-header-breadcrumbs {
            width: 96%;
        }

        .category-breadcrums {
            width: 96%;
        }

    }

    @media (max-width: 1765px) {
        .header-menu-main .nav-item {
            margin: 0 0.8%;
            padding: 0.8rem 0.9rem;
            min-width: fit-content;
        }

        .header-slider-caption-wrapper {
            width: 90%;
        }

        .nav-footer-block-wrapper {
            padding: 0.5rem 0;
        }

        .nav-footer-block li {
            padding: 0 0.5rem !important;
        }

        .photo-section-caption-bottom {
            top: 25%;
        }

        .producteur-de-la-semaine-wrapper {
            width: 95%;
        }

        .parcourir-par-catégories .slick-track {
            height: 200px;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 32% !important;
        }

        .modify-parameter {
            font-size: 13px;
        }
    }

    @media (max-width: 1679px) {

        .header-menu-main .nav-item {
            margin: 0 0.7%;
            min-width: fit-content;
        }

        .header-menu-main .nav-link {
            font-size: 13px !important;
        }

        .header-slider-caption-description {
            width: 80%;
        }

        .parcourir-par-catégories p {
            width: 100%;
        }

        .category .category-products-filters,
        .category .category-product-filter-hierarchy .category-product-filter-hierarchy-title {
            font-size: 12px;
        }

        div.producteur-de-la-semaine {
            width: 60%;
        }

        .producteur-de-la-semaine-wrapper {
            width: 100%;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next {
            top: 35% !important;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 28% !important;
        }

        .client-maturin-caption {
            width: 80%;
        }
    }

    @media (max-width: 1615px) {

        .header-menu-main .nav-item {
            margin: 0 0.5%;
            min-width: fit-content;
        }

        .navbar-brand-wrapper {
            padding-left: 0;
        }

        #category-header-breadcrumbs {
            width: 98%;
        }

        .category-breadcrums {
            width: 98%;
        }
    }

    @media (max-width: 1560px) {

        .header-menu-main .nav-item {
            margin: 0 0.3%;
            min-width: fit-content;
        }

    }

    @media (max-width: 1480px) {

        .header-menu-main .nav-item {
            margin: 0 0.1%;
            min-width: fit-content;
        }

        .btn-icons {
            margin-left: 10%;
        }

        .parcourir-par-catégories .slick-track {
            height: 180px;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 26% !important;
        }

        .sous-menu-main {
            margin-left: 1%;
        }

        .sous-menu-main-items {
            width: 320px;
        }

        .sous-submenu .col-3 {
            min-width: 340px !important;
        }

        .sous-menu-btn button, .sous-menu-btn-main-menu button {
            width: 300px;
        }

        .category-header-top-caption {
            width: 80% !important;
        }

        .sect-other-info {
            margin-bottom: 40px;
        }


    }

    @media (max-width: 1400px) {
        .header-menu-main .nav-link {
            font-size: 12px !important;
        }

        .header-slider-caption-wrapper {
            top: 15% !important;
        }

        .nav-footer-block-wrapper {
            padding: 0.4rem 0;
        }

        .photo-section-caption-top {
            top: 45%;
        }

        .photo-section-caption-top, .photo-section-caption-bottom {
            left: 7%;
        }

        div.producteur-de-la-semaine {
            width: 70%;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next {
            top: 31% !important;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 25% !important;
        }

        .copyright {
            margin: 0 auto;
        }

        .category-search .align-self-end {
            min-width: 50%;
        }

    }

    @media (max-width: 1370px) {

        .header-menu-main .nav {
            width: 100%;
        }

        .header-menu-main .nav-item {
            min-width: fit-content;
            margin: 0;
        }

        .nav-footer-block-wrapper {
            padding: 0.4rem 0;
        }

        .nav-footer-block li {
            padding: 0 0.4rem !important;
        }

        .nav-footer-block-wrapper li {
            margin: 0;
        }

        .btn-icons {
            margin-left: 5%;
        }

        .sous-menu {
            margin-left: 0;
        }

        .client-maturin-caption {
            width: 90%;
        }

        .category .category-products-filters-wrap {
            padding-right: 0;
        }

        .category .category-products {
            width: 95%;
        }

    }

    @media (max-width: 1300px) {
        .header-menu-main .nav-item {
            margin: 0;
            min-width: fit-content;
            padding: 0.7rem .8rem;
        }

        .nav-footer-block li {
            padding: 0 0.3rem !important;
        }

        .parcourir-par-catégories .slick-track {
            height: 200px;
        }
    }

    @media (max-width: 1260px) {

        .header-menu-main .nav-item {
            padding: 0.6rem .6rem;
            min-width: fit-content;
        }

        .navbar-brand-wrapper {
            margin-left: -10px;
        }

        .btn-icons {
            margin-left: 5px;
        }

        .sous-menu-main-items {
            width: 280px;
        }

        .sous-submenu .col-3 {
            min-width: 300px !important;
        }

        .sous-menu-btn button, .sous-menu-btn-main-menu button {
            width: 260px;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 27% !important;
        }

        .messenger-btn {
            top: 2rem;
            right: -100%;
        }

        #category-header-breadcrumbs {
            width: 99%;
        }

        .category-breadcrums {
            width: 99%;
        }

        .product .product-zoom-arrow {
            left: 0;
        }
    }

    @media (max-width: 1210px) {
        .header-menu-main .nav-item {
            padding: 0.6rem .5rem;
            min-width: fit-content;
        }

        .nav-footer-block-wrapper {
            padding: 0.3rem 0;
        }

        .nav-footer-block li {
            padding: 0 0.2rem !important;
        }

        .sous-submenu {
            width: 1100px;
        }

        .sous-menu-main-items {
            width: 260px;
        }

        .sous-submenu .col-3 {
            min-width: 260px !important;
        }

        .sous-menu-btn button, .sous-menu-btn-main-menu button {
            width: 240px;
            font-size: 13px;
        }

        .sous-menu-main {
            margin-left: 5px;
        }


        div.producteur-de-la-semaine {
            width: 80%;
        }

        .category-header-top-caption {
            width: 94% !important;
        }

        .category-products .product-btn-add-to-cart {
            max-width: 90%;
            padding: 0.5rem 0.3rem;
            bottom: 10px;
        }

        .category .category-products-filters, .category .category-product-filter-hierarchy .category-product-filter-hierarchy-title {
            font-size: 9px;
        }

        #categories-gallery .owl-item {
            height: 170px;
        }

        .slick-prev {
            left: -30px !important;
        }

    }

    @media (max-width: 1150px) {

        .header-menu-main .nav-item {
            padding: 0.6rem .48rem;
            min-width: fit-content;
        }

        .header-menu-main .nav-link {
            font-size: 11px !important;
        }

        .header-slider-caption-btn {
            margin-top: 0;
        }

        .header-slider-caption-wrapper {
            top: 10% !important;
        }

        .photo-section-caption-bottom {
            top: 20%;
        }

        .nav-footer {
            width: 90%;
        }

        .sous-submenu {
            width: 990px;
        }
        .sous-menu-main-items {
            width: 240px;
        }

        .sous-submenu .col-3 {
            min-width: 240px !important;
        }

        .sous-menu-btn button, .sous-menu-btn-main-menu button {
            width: 220px;
            font-size: 11px;
        }

        .btn-icons {
            margin-left: 10%;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next {
            top: 30% !important;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 25% !important;
        }

    }

    @media (max-width: 1040px) {
        .header-menu-main .nav-item {
            margin: 0;
            min-width: fit-content;
        }

        .header-menu-main .nav-item {
            padding: 0.3rem;
        }
        .nav-footer-block-wrapper {
            padding: 0.2rem 0;
        }

        .nav-footer-block li {
            padding: 0 0.1rem !important;
        }
        .sous-submenu {
            width: 900px;
        }

        .photo-right-section-top {
            height: 60%;
        }

        .photo-section-caption-top {
            top: 35%;
        }

        .photo-right-section-bottom {
            height: 40%;
        }

        div.producteur-de-la-semaine {
            width: 90%;
        }

        .category-header-top-caption span {
            font-size: 37px;
        }

        .category-header-top-img {
            height: 200px;
        }

    }

    @media (max-width: 992px) {

        .header-slider, .category-header-top {
            margin-top: 20px;
        }

        .category .producer-header-top-image {
            height: 205px;
        }

        .header-slider-caption {
            top: 10% !important;
        }

        .header-slider-caption-description {
            width: 90%;
        }

        .nav-wrapper .nav li a {
            line-height: 37px;
        }

        .navbar-brand-wrapper {
            text-align: left !important;
            position: relative;
            margin-left: 0px;
        }

        .navbar-brand {
            float: left;
        }

        .btn-icons {
            margin-left: 5px;
            margin-top: 5px;
        }

        .parcourir-par-catégories .slick-prev, .parcourir-par-catégories .slick-next {
            top: 35% !important;
        }

        .section-2 {
            width: 95%;
        }

        .parcourir-par-catégories .slick-track {
            height: 230px;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next {
            top: 24% !important;
        }


        .nav-footer-help {
            margin-right: 0;
        }

        .nav-footer-block {
            max-width: 30%;
            margin-bottom: 15px;
        }

        .nav-footer-block a {
            font-size: 13px;
        }

        .nav-footer-block li {
            padding: 0 0.5rem !important;
        }

        .nav-footer-block-wrapper {
            padding: 0.5rem 0;
        }

        .masonry-layout {
            column-count: 3;
            -moz-column-count: 3;
            -webkit-column-count: 3;
        }

        .product-quantity-value .button-orange {
            padding: 1px 6px;
        }

        .header-search {
            margin-bottom: 20px;
        }

        .product-property-description .about-company-contact__button {
            margin-bottom: 0;
        }

        .category-breadcrums,#category-header-breadcrumbs  {
            width: 100%;
            margin: 20px 15px 50px 15px;
        }

        .category .about-company {
            margin-top: 20px;
        }


        .category .about-company .logo-company-wrap {
            margin-top: 50px;
            max-width: inherit;
            text-align: center;
            padding-left: 15px;
        }

        .category .about-company .about-company__descripton {
            text-align: center;
        }
        .category .about-company .about-company__description-text {
            width: 100%;
        }

        .category .about-company .about-company__descripton .about-company__name {
            margin-top: 45px;
            font-size: 24px;
        }

        .category .about-company .nav-subscription-mobile {
            position: absolute;
            right: 15px;
        }

        .category-products .amount-info {
            text-align: center;
        }

        .category-products .category-search {
            margin-top: 46px;
        }

        .category-products .category-search .no-gutters {
            margin-bottom: 25px;
        }

        .category .category-products-filters, .category .category-product-filter-hierarchy .category-product-filter-hierarchy-title {
            font-size: 11px;
        }

        .category .products-wrap {
            padding: 0;
        }

        .select-styled {
            white-space: nowrap;
        }

        .sect-customer-reviews {
            padding-bottom: 45px;
        }

        .sect-customer-reviews .column-reviews {
            margin: 0 auto;
            border-top: none;
        }

        .modal-product-amount .button-200 {
            width: 185px;
        }

        .product .product-zoom-arrow {
            left: -8%;
        }

    }

    @media (max-width: 847px) {
        .nav-footer-help li {
            margin-right: 30px;
        }

        .parcourir-par-catégories .slick-track {
            height: 205px;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .parcourir-par-catégories .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next, .parcourir-par-catégories .slick-next {
            top: 21% !important;
        }

        div.producteur-de-la-semaine {
            width: 100%;
        }

        .producteur-de-la-semaine-wrapper {
            width: 96%;
        }

        .masonry-layout {
            column-count: 2;
            -moz-column-count: 2;
            -webkit-column-count: 2;
        }

        .category-products-filters {
            font-size: 14px;
        }

        .pswp__counter {
            display: block;
        }

        .product .product-zoom-arrow {
            left: -13%;
        }


    }

    @media (max-width: 767px) {
        section {
            margin: 3rem auto;
        }

        .section-title {
            width: 90%;
            font-size: 24px;
            line-height: 1.1;
        }

        .header-slider-caption {
            top: 2% !important;
        }


        .section-2 {
            display: none;
        }

        .producteur-de-la-semaine-caption {
            padding-top: 20px;
        }

        .parcourir-par-catégories .slick-track {
            height: 230px;
        }

        div.quest-ce-que-maturin {
            margin: 3rem auto;
        }

        div.quest-ce-que-maturin {
            width: 90%;
        }
        .client-maturin-caption {
            padding-top: 20px;
        }

        .nos-clients-nous-disent-right {
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
        }

        .nos-clients-nous-disent-right-wrapper {
            padding: 5rem;
        }

        .producteur-de-la-semaine {
            width: 100% !important;
            text-align: center;
        }

        .producteur-de-la-semaine .col {
            margin: 0 auto !important;
        }

        .messenger-btn {
            display: none;
        }

        .nav-footer-help li {
            margin-right: 10px;
        }

        .nav-footer-inline {
            text-align: center;
            width: 100%;
            padding-right: 15px !important;
            padding-left: 15px !important;
            margin-top: 20px;
        }

        .nav-footer-help li {
            display: block;
        }

        .nav-footer-block {
            max-width: 50%;
        }

        .nav-footer-bottom-mobile li a {
            position: absolute !important;
        }

        .nav-footer-bottom-mobile {
            text-align: left !important;
        }


        .modal-open {
            position: fixed;
        }

        .btn-registr-modal-close {
            display: block;
        }

        .d-f {
            display: none;
        }

        .d-m {
            display: block;
        }

        .position-absolute-bottom {
            position: relative !important;
        }


        .category-header-top-img {
            height: 150px;
        }

        .category-products {
            width: 100%;
            padding: 0 10px;
            margin: 1rem auto !important;
        }

        .category-products-result {
            padding: 0.6rem 15px;
        }

        #checked-filters {
            /*margin-top: 40px;*/
            padding: 0 15px;
        }

        .category-products-filters-title {
            line-height: 2.5;
            margin: 0;
            font-size: 18px;
        }

        .category-product-filter.first {
            margin: 1% -15px 7% -15px;
        }

        .category-product-filter .category-product-filter-title {
            font-size: 18px;
        }

        .category-product-filter-checkbox, .category-product-filter-default {
            font-size: 16px;
        }

        .products-wrap__products .product-title {
            font-size: 15px;
        }

        .category-products .more-products {
            margin-top: 5px;
        }

        /*.category-products .search-input {*/
        /*font-size: 15px;*/
        /*}*/

        .category-products .search-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font-size: 15px;
        }
        .category-products .search-input::-moz-placeholder { /* Firefox 19+ */
            font-size: 15px;
        }
        .category-products .search-input:-ms-input-placeholder { /* IE 10+ */
            font-size: 15px;
        }
        .category-products .search-input:-moz-placeholder { /* Firefox 18- */
            font-size: 15px;
        }

        .sect-products-categories {
            margin-top: 0;
            width: 100%;
            padding: 20px 15px 30px 15px;
        }

        .sect-products-categories .heading__text, .heading-mobile__text {
            margin-bottom: 18px;
            font-size: 15px;
            font-weight: bold;
        }

        .category-products .more-products a {
            width: 246px;
            height: 44px;
            line-height: 44px;
            font-size: 13px;
        }

        .sect-customer-reviews .column-reviews {
            padding-top: 0;
        }


        .checkbox {
            padding-left: 45px;
            line-height: 35px;
        }

        .checkbox i {
            width: 25px;
            height: 25px;
        }

        .checkbox input + i:after {
            width: 25px;
            height: 25px;
        }

        .product-board {
            padding-left: 0px;
        }



        .vous-aimeriez-aussi {
            width: 100%;
            margin: 0 auto;
        }

        #product {
            width: 100%;
        }

        #product .section-title {
            margin-top: 20px;
            width: 100%;
            margin-bottom: 0px !important;
        }

        #product .product-quantity-title {
            margin-top: 20px;
        }

        .product-quantity, .product-board .nav-suivre {
            padding: 0 2rem;
        }

        .product .product-zoom-arrow {
            display: none;
        }



    }

    @media (max-width: 750px) {
        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .parcourir-par-catégories .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next, .parcourir-par-catégories .slick-next {
            top: 33% !important;
        }

        #categories-gallery .owl-item {
            height: 157px;
        }
    }

    @media (max-width: 680px) {
        .header-slider-caption {
            top: 0 !important;
        }

        .header-slider-caption-wrapper {
            width: 80%;
        }

        .header-slider-caption-title span {
            font-size: 32px;
        }

        .header-slider-caption-description {
            font-size: 18px;
        }

        .header-slider .slick-slide img {
            height: 250px;
        }

        .header-slider-caption-btn {
            margin-top: 3%;
        }

        .section-title-line:after, .section-title-line:before {
            display: none;
        }

        .client-maturin-caption {
            width: 100%;
        }

        .parcourir-par-catégories .slick-track {
            height: 210px;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .parcourir-par-catégories .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next, .parcourir-par-catégories .slick-next {
            top: 30% !important;
        }
    }


    @media (max-width: 600px) {
        .slick-slide {
            /*max-width: 157px !important;*/
        }

    }

    @media (max-width: 575px) {
        .header-slider-caption-title span {
            text-align: center;
        }

        .header-slider-caption-title, .header-slider-caption-description {
            text-align: center;
            margin: 0 auto;
        }

        .header-slider-caption-title {
            line-height: 1.1;
            width: 90%;
        }

        .header-slider-caption-description {
            width: 95%;
        }

        .header-slider-caption-wrapper {
            text-align: center;
        }

        .header-slider .slick-prev, .header-slider .slick-next {
            width: 35px;
            height: 60px;
        }

        .slick-arrow i {
            font-size: 20px;
        }

        .header-slider-caption-btn {
            margin-top: 30px;
        }

        .navbar-brand {
            font-size: 24px;
        }

        .nav-wrapper .nav li a {
            line-height: 2.1;
        }

        .navbar-toggler {
            height: 45px;
        }

        .nav-link {
            padding: .5rem .5rem;
        }

        .nav-wrapper .btn-icons {
            margin-left: 5px !important;
            height: 20px;
            width: 20px;
            top: 10px;
        }

        .btn-icons {
            margin-top: 0;
        }

        .btn-icons-cart-count {
            width: 11px;
            height: 10px;
            line-height: 1.6;
            right: -5px;
            font-size: 7px;
        }

        section {
            margin: auto;
        }

        .section-title {
            margin-bottom: 20px !important;
        }

        .section-3, .section-4-mobile {
            margin-top: 30px;
        }

        .section-5 {
            margin-top: 40px;
        }

        .section-5 .section-title {
            line-height: 1.1;
        }

        .section-6 {
            margin: 30px auto !important;
        }

        .section-3-copy {
            margin-top: 50px;
        }

        .section-3-copy .section-title {
            margin-bottom: 5px !important;
        }


        .produit-a-proximite {
            display: none !important;
        }

        .produit-a-proximite-mobile {
            display: block;
            padding: 0 15px;
        }

        .produit-en-vedette {
            width: 100%;
            padding: 0 15px;
        }

        .produit-a-proximite-mobile .product-group {
            padding-top: 10px;
        }

        .produit-en-vedette .product-title {
            font-size: 13px;
        }

        .produit-en-vedette .product-subtitle {
            font-size: 11px;
        }

        .produit-en-vedette .product-price {
            font-size: 11px;
        }


        .produit-en-vedette .product-btn-add-to-cart, .produit-a-proximite-mobile .product-btn-add-to-cart {
            width: 70% !important;
        }

        .produit-en-vedette .product-btn-overview, .produit-a-proximite-mobile .product-btn-overview {
            width: 40%;
        }

        .parcourir-par-catégories .slick-track {
            height: 190px;
        }


        .nos-clients-nous-disent-right, .nos-clients-nous-disent-left {
            height: auto !important;
        }

        .nos-clients-nous-disent-right-profile {
            margin: 15% auto 5% auto;
        }

        .nos-clients-nous-disent-right .button {
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .nos-clients-nous-disent-right-wrapper {
            padding: 5rem 0.5rem;
        }

        .nos-clients-nous-disent-right-caption {
            font-size: 20px;
        }

        div.producteur-de-la-semaine {
            margin: 0rem auto 50px auto
        }

        div.producteur-de-la-semaine:last-child {
            margin: 25px auto !important;
        }

        .button-en-voir-plus {
            margin: 3rem auto auto auto;
        }


        .pb-15-pt-5-screen-575 {
            padding-bottom: 15px;
            padding-top: 5px;
        }


        #registrModal .tab-pane .row {
            padding-bottom: 10px;
        }

        #registrModal h5 {
            padding-bottom: 10px;
            margin-top: -10px;
        }

        .m--30 {
            margin-right: -20px;
            margin-left: -20px;
            margin-bottom: 0;
        }

        .product-title {
            margin-bottom: 0px !important;
        }

        .product-btn-overview, .product-btn-add-to-cart {
            display: none;
        }

        .modifiezVosParamètres {
            padding: 0;
        }

        .masonry-layout__panel-content{
            padding: 0;
        }

        .category-header-top-caption span {
            font-size: 32px;
        }

        .category-search .align-self-end {
            padding: 0 20px;
        }

        .category .category-search .align-self-end {
            padding: 1%;
        }

        .category-products .product-btn-add-to-cart {
            padding: 0.5rem 0;
            font-size: 12px;
        }


        .category-products-filter-by {
            font-size: 12px;
            padding: 0.4rem 0.4rem;
            margin: 0.2rem 0 0.2rem 0;
            position: relative;
        }

        .category-products-filter-by-btn-close {
            margin-left: 5px;
            /*font-size: 11px;*/
            /*float: right;*/
            /*padding: 1px 4px 1px 6px;*/
            /*position: absolute;*/
            /*top: 0;*/
            /*bottom: 0;*/
            /*right: 5px;*/
        }

        /*.category-products-filter-by-btn-close img{*/
        /*width: 7px;*/
        /*height: auto;*/
        /*}*/


        .column-reviews .comment-name,
        .column-reviews .comment-text {
            font-size: 13px;
        }


        .column-reviews article .col-9 {
            padding-left: 0;
        }

        .product-property-description {
            font-size: 13px;
        }

        .category-products .products-wrap {
            max-width: 100%;
            margin: 0 auto;
        }

        .divider{
            width: 98%;
        }

        .m-r-l-10{
            margin: 1% auto!important;
            width: 100%!important;
        }
    }

    @media (max-width: 480px) {

        .header-slider-caption-description {
            font-size: 13px;
        }

        .header-slider-caption-btn {
            font-size: 12px;
            width: 50%;
        }

        .header-slider-caption-title span {
            font-size: 28px;
        }

        .header-slider-caption-title {
            line-height: 0.9;
        }


        .nav-footer-block {
            max-width: 85%;
        }

        .section-4 {
            display: none;
        }

        .section-4.no-background {
            display: block;
        }

        .section-4-mobile {
            display: block !important;
            background: #f2f1f1;
            margin-right: -15px;
            margin-left: -15px;
            padding-bottom: 20px;
        }

        .section-4-mobile .sous-menu-mobile a {
            color: black;
        }

        .section-4-mobile .section-title {
            text-align: left;
            font-size: 16px;
            font-family: NunitoSans-Bold, serif;
            padding: 20px !important;
            margin: 0 !important;
            width: 100%;
        }

        .produit-en-vedette .slick-track {
            /*right: 40px;*/
            left: auto!important;
        }

        .vous-aimeriez-aussi .slick-track {
            right: 30px;
            left: auto!important;
        }
        .produit-en-vedette .product-btn-add-to-cart, .produit-a-proximite-mobile .product-btn-add-to-cart {
            font-size: 8px;
            padding: 0.5rem 0.2rem;
        }

        .produit-en-vedette .product-btn-overview {
            font-size: 10px;
        }

        .product-btn-like {
            top:10px;
            right:10px;
        }
        .produit-a-proximite-mobile .product-btn-like {
            top:20px;
            right:10px;
        }

        div.producteur-de-la-semaine img {
            width: 100%;
            height: auto;
        }

        .nos-clients-nous-disent-left {
            height: 360px !important;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .parcourir-par-catégories .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next, .parcourir-par-catégories .slick-next {
            top: 25% !important;
        }

        .product-price .product-price-before-sale{
            white-space: pre;
            margin: 0;
        }

        .category-header-top-img {
            height: auto;
        }

        .category-header-top-caption span {
            font-size: 30px;
        }

        .category-product-filter-hierarchy-back-btn{
            line-height: 2.4;
        }

        .category-products-filter-by span {
            padding-right: 12px;
        }

        .product-quantity .button-orange {
            width: calc(100% - 100px) !important;
        }

        .product-quantity .button-black {
            width: 100% !important;
        }

        .product-board .nav-suivre {
            padding: 0;
            text-align: center;
        }

        .select-options {
            font-size: 15px;
        }

        .product-property {
            margin: 0 -15px;
            border-right: none;
            border-left: none;
        }
        .product-property-title{
            border-right: none;
            border-left: none;
        }

        .comments article {
            padding: 10px 0 0 0;
        }



        /*.column-select {*/
        /*min-width: 48%;*/
        /*}*/
    }






    @media (max-width: 380px) {
        .padding-right-10-screen-380 {
            padding-right: 10px;
            padding-left: 10px;
        }

        .navbar-brand {
            margin-right: 5px;
            font-size: 18px;
            line-height: 1.9;
        }


        .navbar-toggler {
            height: auto;
        }

        .navbar-toggler i {
            font-size: 18px;
            line-height: 1.9;
        }

        .navbar-toggler {
            padding: .25rem 0.3rem;
        }

        .btn-icons-cart {
            padding: .5rem 0.5rem;
        }

        .header-slider-caption-title {
            width: 100% !important;
        }

        .header-slider-caption-btn {
            margin-top: 35px;
            width: 60%;
        }

        .nos-clients-nous-disent-left {
            height: 280px !important;
        }

        .produit-en-vedette .slick-prev, .produit-a-proximite .slick-prev, .parcourir-par-catégories .slick-prev, .produit-en-vedette .slick-next, .produit-a-proximite .slick-next, .parcourir-par-catégories .slick-next {
            top: 24% !important;
        }

        .category-products .product-btn-add-to-cart {
            padding: 0.5rem 0;
            font-size: 8px;
        }

        .category-header-top-caption span {
            font-size: 24px;
        }

        .category .category-products-filters-btn {
            padding-right: 3px;
        }

        .select-category .dropdown-toggle, .select-trier .dropdown-toggle {
            padding-left: 6px;
        }

        .select-category .dropdown:after, .select-trier .dropdown:after {
            right: 5px;
        }

        .select-trier .select-options {
            left: -104% !important;
            width: 204%;
        }

    }

    @media (max-width: 350px) {
        .category-header-top-caption span {
            font-size: 22px;
        }

        .d-480-none {
            display: none;
        }
    }
}
